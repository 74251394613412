import React from "react";
import ReactPlayer from "react-player";
import videoImg from "../assets/assets/videoImage.svg";
const Player = () => {
  return (
    // <div className="grid grid-cols-12  player py-[20px]">
       <div className='player-wrapper py-[20px]'>
        <ReactPlayer
          className='react-player mx-auto'
          url='https://youtu.be/-HBmsNlUeHE'
          width='100%'
          height='100%'
          light={
            <img src={videoImg} className="rounded-[45px] " alt="Thumbnail" />
          }
        />
    </div>
  );
};

export default Player;
