import React from "react";
import { Hero, Navbar } from "../components";
import CircleSection from "../components/CircleSection";
import DetailSection from "../components/DetailSection";
import KnowMore from "../components/KnowMore";
import ScrollToTop from "../components/ScrollToTop";
// import FeaturesSection from "../components/FeaturesSection";
import styles from "../style";

const Home = () => {
  return (
    <div className="w-full grid grid-cols-4">
     
      {/* <div
        className={`sm:min-h-screen min-h-[1300px] bg-white bg-hero-pattern bg-center bg-cover bg-blend-multiply pb-32 md:pb-16 sm:pb-4 xs:pb-64`}
      > */}
      <div className={`bg-white col-span-4`}>
        <div
          className={`bg-hero-pattern bg-center bg-cover bg-blend-multiply lg:h-[120vh] md:h-[120vh] sm:h-[120vh] xs:h-[130vh] xl:h-[120vh] lg:bg-hero-pattern md:bg-hero-pattern sm:bg-hero-pattern xs:bg-wave-img-mb`}
        >
          <Navbar />
          <Hero />
        </div>
      </div>
      {/* </div> */}

      <div className={`col-span-4 bg-white ${styles.flexCenter}`}>
        <div className={`py-6`}>
          <CircleSection />
        </div>
      </div>

      {/* <DetailSection /> */}

      <div className={`col-span-4 ${styles.flexCenter} ${styles.paddingY}`}>
        <div className={`w-full`}>
          <DetailSection />
        </div>
      </div>

      <div className={`col-span-4 ${styles.flexCenter} ${styles.paddingY} sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem]`}>
        <div className={`w-full`}>
          <KnowMore />
        </div>
      </div>
    </div>
  );
};

export default Home;
