import React from 'react'

const IndividualTimeline = ({icon, year, detail,textColor="#742B88"}) => {
  return (
    <div className="p-4 text-center inline-block">
       <div className='items-center inline-block'> <img src={icon} alt="icon" /></div>
        <div className={`mt-[24px] font-bold text-[24px] leading-[34px] text-center w-full fym-text-gradient`}>{year}</div>
        <p className='text-[16px] leading-[28px] text-[#8D7D9C]'>{detail}</p>
    </div>
  )
}

export default IndividualTimeline