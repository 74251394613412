import React from "react";
import logoForLearnMore from "../assets/assets/submarklogo_light.svg";
import Player from "./Player";

const LearnMoreContent = () => {
  return (
    <>
      <section className="grid grid-cols-12 items-center text-white py-12">
        <div className=" col-span-12 space-y-5 relative sm:ml-[50%] xs:ml-[44%]">
          <img src={logoForLearnMore} alt="logoLearnMore" />
        </div>
        <div className="col-start-2 col-span-10 content-center">
          <div className="headTextForLearnSection py-[20px] sm:px-40">
            We're all about using the power of music, empathy, and tech to make
            the world a mentally healthier place.
          </div>
         
        </div>
        <div className="col-start-3 col-span-8 content-center">
        <Player />
        </div>
      </section>
    </>
  );
};

export default LearnMoreContent;
