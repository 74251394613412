import { useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import manImage from "../assets/foundingKnowMore.png";
import manImageMb from "../assets/foundingKnowMore-mb.png";
import Button from "./Button";
import FormModal from "./FormModal";

const FoundingKnowMore = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const statusModal = (flag) => {
    setOpen(flag);
    navigate("/");
  };
  return (
    <>
      {/* <section
        className={`absolute grid grid-cols-12 lg:gap-x-28 md:gap-x-28 sm:gap-x-8 rounded-[40px] bg-gradient-to-r from-[#2B499E] to-[#742B88] sm:h-[598px] w-full sm:mb-12 sm:mt-0 mt-[801px]`}
      >
        <div className="md:col-span-5 lg:col-span-5 sm:col-span-4 xs:col-span-12">
          <img src={manImage} alt="" className=" sm:absolute sm:top-[-85px] sm:right-[640px] relative sm:h-[550px] sm:w-[550px] " />
        </div>
        <div className=" md:col-span-7 lg:col-span-7 sm:col-span-8 xs:col-span-12">
          <div className=" sm:mt-[-05px]">
            <div className="font-bold text-[40px] text-white">
              We need your help!
            </div>
            <p className="font-[450] text-[14px] leading-[30px] text-white mt-[16px] sm:pr-12 xs:pr-[0px]">
              By signing up to become a BETA tester, you'll get exclusive access
              to our AI-powered music app, designed to help you chill out or
              find your perfect vibe. And the best part? You'll also have a
              chance to help us shape the future of FYM with your personal
              feedback! So join us and let's make FYM even better together!
            </p>
          </div>
          <Button
            styles={`px-[32px] py-[16px] sm:w-[250px] sm:h-[48px] mt-[50px]`}
            text="Become a BETA Tester"
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
      </section> */}
      <section>
        <div className="flex lg:flex-row  md:flex-row sm:flex-col xs:flex-col justify-center bottom-0  xl:rounded-[40px] xs:rounded-[22px] lg:rounded-[40px] sm:rounded-[40px] md:rounded-[40px] bg-gradient-to-r from-[#2B499E] to-[#742B88] xl:text-left xs:text-center lg:text-left sm:text-left md:text-left">
          <div className="flex-initial lg:w-[40%] md:w-[40%] sm:w-[100%] lg:mt-[-11%] md:mt-[-11%] sm:mt-[-11%] lg:ml-[1%] md:ml-[1%] sm:ml-[1%]  md:block sm:block lg:block xs:hidden">
            <img src={manImage} alt="" className="w-[550px] h-[550px] m-auto" />
          </div>
          <div className="flex-initial lg:w-[60%] md:w-[60%] sm:w-[100%]">
            <div className="py-[40px] px-[40px] lg:px-[40px] xl:px-[40px] md:px-[40px] sm:px-[40px] xs:px-[20px]">
              <div className="sm:mt-[-05px]">
                <div className="font-bold text-[40px] text-white lg:text-[40px] xl:text-[40px] md:text-[40px] sm:text-[40px] xs:text-[24px] lg:leading-[40px] xl:leading-[40px] md:leading-[40px] sm:leading-[40px] xs:leading-[34px] ">
                  We need your help!
                </div>
                <p className="font-[450] text-[18px] leading-[30px] text-white mt-[16px] sm:pr-12 xs:pr-[0px]">
                  By signing up to become a BETA tester, you'll get exclusive
                  access to our AI-powered music app, designed to help you chill
                  out or find your perfect vibe. And the best part? You'll also
                  have a chance to help us shape the future of FYM with your
                  personal feedback! So join us and let's make FYM even better
                  together!
                </p>
              </div>
              <Button
                styles={`px-[32px] py-[16px] sm:w-[250px] sm:h-[48px] mt-[50px]`}
                text="Become a BETA Tester"
                onClick={() => {
                  setOpen(true);
                }}
              />
            </div>
          </div>
          <div className="flex-initial self-center  md:hidden sm:hidden lg:hidden xs:block ">
            <img src={manImageMb} alt="" className="w-[300px] h-[250px] m-auto" />
          </div>
        </div>
        <FormModal
          onOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          status={statusModal}
        />
      </section>

    </>
  );
};

export default FoundingKnowMore;
