import React from "react";
import ankushImg from "../assets/assets/ankush-img.svg";

const Ankush = () => {
  return (
    // <section className="mt-8 bg-[#282229]">
    //   <div className="grid lg:grid-cols-12 md:grid-cols-12  sm:grid-cols-12 xs:grid-cols-1 py-12 text-white text-center">
    //     <div className="col-start-5 col-span-4 content-center">
    //       <img src={ankushImg} alt="ankushImg" />
    //       <div className="rip text-center">Rest in Peace</div>

    //       <div className="ankushText mt-[24px]">Ankush Jacob</div>

    //       <div className="timeLine mt-[24px]">
    //         December 6' 1987 - May 9' 2021
    //       </div>
    //     </div>
    //     <div className="col-start-2 col-span-10">
    //       <div className="textInfoForAnkush max-w-6xl mx-auto mt-4 sm:px-0 px-[12px]">
    //         Ankush Jacob was an incredible machine learning engineer, phenomenal
    //         human being, an amazing friend, and the cofounder of Feelyou Music.
    //         <br />
    //         <br />
    //         Ankush was a selfless man, who was so passionate about helping
    //         others. He was a beyond brilliant mind, and an endlessly kind soul.
    //         His drive to help people with their mental health through artificial
    //         intelligence and machine learning was unmatched, and Feelyou Music
    //         would not be here today without his dedication. He is truly an angel
    //         to all of us.
    //         <br />
    //         <br />
    //          We love and miss you Ankush, thank you for everything.
    //       </div>
    //     </div>
    //   </div>
    //   {/* <div className="flex flex-col items-center py-12 text-white">
    //       <img src={ankushImg} alt="ankushImg" />
    //       {/* <img src={ankushRIP} alt="ankushRIP" />
    //       <div className="rip">Rest in Peace</div>

    //       <div className="ankushText mt-[24px]">Ankush Jacob</div>

    //       <div className="timeLine mt-[24px]">December 6' 1987 -  May 9' 2021</div>

    //       <div className="textInfoForAnkush max-w-6xl mx-auto mt-4 sm:px-0 px-[12px]">
    //         Ankush Jacob was an incredible machine learning engineer, phenomenal
    //         human being, an amazing friend, and the cofounder of Feelyou Music.
    //         Ankush was a selfless man, who was so passionate about helping
    //         others. He was a beyond brilliant mind, and an endlessly kind soul.
    //         His drive to help people with their mental health through artificial
    //         intelligence and machine learning was unmatched, and Feelyou Music
    //         would not be here today without his dedication. He is truly an angel
    //         to all of us. We love and miss you Ankush, thank you for everything.
    //       </div>
    //     </div> */}
    // </section>

    <section class="mt-8 bg-gray-900 sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem]">
      <div class="grid grid-cols-1 md:grid-cols-12 py-12 text-white">
      
        <div className="feelyoutextContainer lg:flex xl:flex md:flex sm:flex xs:hidden ">
              <div className="dash-g"></div>
              <div className="feelyoutext-g">Founding FeelYou™</div>
            </div>
       
        <div class="md:col-start-5 md:col-span-4 flex flex-col items-center">
          <img src={ankushImg} alt="ankushImg" class="w-full" />
          <div class="rip text-center">Rest in Peace</div>
          <div class="ankushText mt-4 md:mt-8 text-2xl md:text-4xl font-bold">
            Ankush Jacob
          </div>
          <div class="timeLine mt-2 md:mt-4 text-lg md:text-2xl">
            December 6' 1987 - May 9' 2021
          </div>
        </div>
        <div class="md:col-start-2 md:col-span-10 max-w-6xl mx-auto mt-8 md:mt-0 px-4 md:px-0">
          <div class="textInfoForAnkush text-lg md:text-xl leading-relaxed">
            Ankush Jacob was an incredible machine learning engineer, phenomenal
            human being, an amazing friend, and the cofounder of FeelYou™ Music.
            <br />
            <br />
            Ankush was a selfless man, who was so passionate about helping
            others. He was a beyond brilliant mind, and an endlessly kind soul.
            His drive to help people with their mental health through artificial
            intelligence and machine learning was unmatched, and FeelYou™ Music
            would not be here today without his dedication. He is truly an angel
            to all of us.
            <br />
            <br />
            We love and miss you Ankush, thank you for everything.
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ankush;
