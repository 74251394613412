import React from "react";
import styles from "../style";

const Circle = ({ title, icon, detail, textColor }) => {
  return (
    <div className="circleWidthHeight  bg-[#F5F7FC] rounded-full shadow-lg">
      <div className={`${styles.flexCenter} flex-col px-[60px] py-[74px] lg:pt-[74px] xl:pt-[74px] md:pt-[74px] sm:pt-[74px] xs:pt-[32px]  `}>
        {/* icon */}
        <img src={icon} alt={title} className="w-[80px] h-[80px]" />

        {/* content */}
        <div className="">
          <div
            className={`font-bold sm:text-[24px] sm:leading-[34px]  xs:text-[20px] xs:leading-[30px] text-center w-full text-[${textColor}]`}
          >
            {title}
          </div>
          <p className="mt-[16px] font-medium sm:text-[18px] sm:leading-[30px] xs:[16px] xs:leading-[20px] text-center text-[#716480]">
            {detail}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Circle;
