import React from "react";
import logo from "../assets/mainlogo_gradient.png";

export default function MyModal({isVisible,onClose, content}) {
 
  if (!isVisible) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center z-[10]">
      <div className="bg-white sm:w-[35rem] xs:w-11/12 md:w-[35rem] lg:w-[35rem] p-8 rounded-lg flex flex-col">
        <img
          src={logo}
          alt="fym"
          className="w-[125px] h-[24px] place-self-center"
        />
        <button className="text-gray text-sm place-self-end mt-[-20px]" onClick={onClose}>
          X
        </button>
        <div className="py-[20px]">
          {content}
        </div>
      </div>

      {/* <div className="bg-white p-2 rounded w-72">
        <h1 className="font-semibold text-center text-xl text-gray-700">
          Welcome back
        </h1>
        <p className="text-center text-gray-700 mb-5">Sign in</p>

        <div className="flex flex-col">
          <input
            type="text"
            className="border border-gray-700 p-2 rounded mb-5"
            placeholder="email@example.com"
          />
          <input
            type="text"
            className="border border-gray-700 p-2 rounded mb-5"
            placeholder="********"
          />
        </div>
        <div className="text-center">
          <button className="px-5 py-2 bg-gray-700 text-white rounded">
            Sign in
          </button>
        </div>
      </div> */}
    </div>
  );
}
