import { useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../style";
import Button from "./Button";
import imageForApproachTo from "../assets/assets/imageForApproachToTry.svg";
import FormModal from "./FormModal";

const KnowMore = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const statusModal = (flag) => {
    setOpen(flag);
    navigate("/");
  };
  return (
    <>
  <section class="grid grid-cols-12 lg:rounded-[40px] xs:rounded-[24px] bg-gradient-to-r from-[#2B499E] to-[#742B88] lg:relative xl:text-left xs:text-center lg:text-left sm:text-left md:text-left">
  <div class="lg:col-span-7 md:col-span-7 col-span-12 lg:pl-[80px] md:pl-[80px] lg:pt-[20px] md:pt-[20px] lg:pb-[20px] md:pb-[20px] sm:pt-[20px] sm:pl-[50px] xs:p-[24px] ">
    <div class="font-bold lg:text-[40px] xl:text-[40px] xs:text-[20px] sm:text-[40px] md:text-[40px]   xl:text-left xs:text-center lg:text-left sm:text-left md:text-left text-white mb-6">
      Want to be one of the first <br /> people to try FeelYou™ Music?
    </div>
    <p class="font-[450] text-[18px] leading-[30px] text-white mt-[16px] sm:pr-[50px]">
      By signing up to become a BETA tester, you'll get exclusive access
      to our AI-powered music app, designed to help you chill out or find
      your perfect vibe. And the best part? You'll also have a chance to
      help us shape the future of FYM with your personal feedback! So join
      us and let's make FYM even better together!
    </p>
    <Button
            styles={`px-[32px] py-[16px] md:w-[250px] md:h-[48px] mt-[50px]  hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-[#742B88] focus:ring-opacity-50`}
            text="Become a BETA Tester"
            onClick={() => {
              setOpen(true);
            }}
          />
  </div>
  <div class="lg:col-span-5 md:col-span-5 col-span-12 md:my-0 xs:my-0 my-10 lg:pl-[80px] lg:pr-[80px] lg:pt-0 lg:pb-0 lg:absolute lg:bottom-0 lg:right-0">
    <img src={imageForApproachTo} alt="" class="w-full h-auto mx-auto lg:w-[497px] lg:h-auto" />
  </div>
  <FormModal
          onOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          status={statusModal}
        />
</section>
      {/* <section className="grid grid-cols-12  bottom-0 rounded-[40px] bg-gradient-to-r from-[#2B499E] to-[#742B88]">
        <div className="lg:col-span-7 md:col-span-7 sm:col-span-12 xs:col-span-12 lg:pl-[80px] md:pl-[80px] lg:pt-[20px] md:pt-[20px] lg:pb-[20px] md:pb-[20px] sm:pt-[20px] sm:pl-[50px] xs:p-[24px]">
          <div className="font-bold text-[40px] text-white">
            Want to be one of the first <br /> people to try FeelYou™ Music?
          </div>
          <p className="font-[450] text-[18px] leading-[30px] text-white mt-[16px] sm:pr-[50px]">
            By signing up to become a BETA tester, you'll get exclusive access
            to our AI-powered music app, designed to help you chill out or find
            your perfect vibe. And the best part? You'll also have a chance to
            help us shape the future of FYM with your personal feedback! So join
            us and let's make FYM even better together!
          </p>
          <Button
            styles={`px-[32px] py-[16px] md:w-[250px] md:h-[48px] mt-[50px]`}
            text="Become a BETA Tester"
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
        <div
          className={`lg:col-span-5 md:col-span-5  sm:col-span-12  xs:col-span-12 md:my-0 my-10`}
        >
          <img
            src={imageForApproachTo}
            alt=""
            className="md:absolute w-[497px] h-auto md:mt-[-119px]  md:left-[38%] sm:ml-[19%] sm:mb-[-40px] xs:mb-[-20px]"
          />
        </div>
      </section> */}

      {/* <section
        className={`relative md:h-[598px] w-full md:mb-12  ${styles.flexCenter} sm:mt-[420px] xs:mt-[1026px]`}
      >
        <div className="absolute w-full md:h-[428px] bottom-0 rounded-[40px] bg-gradient-to-r from-[#2B499E] to-[#742B88]">
          <div className="flex md:flex-row sm:flex-col xs:flex-col justify-between items-center md:px-[80px] md:py-[80px] px-6 py-6 ">
            <div className="flex flex-col max-w-[700px] ">
              <div className=" sm:mt-[-40px]">
                <div className="font-bold text-[40px] text-white">
                  Want to be one of the first <br /> people to try FeelYou™
                  Music?
                </div>
                <p className="font-[450] text-[18px] leading-[30px] text-white mt-[16px] sm:pr-[50px]">
                  By signing up to become a BETA tester, you'll get exclusive
                  access to our AI-powered music app, designed to help you chill
                  out or find your perfect vibe. And the best part? You'll also
                  have a chance to help us shape the future of FYM with your
                  personal feedback! So join us and let's make FYM even better
                  together!
                </p>
              </div>
              <Button
                styles={`px-[32px] py-[16px] md:w-[250px] md:h-[48px] mt-[50px]`}
                text="Become a BETA Tester"
                onClick={() => {
                  setOpen(true);
                }}
              />
            </div>

          
            <div>
              <img
                src={imageForApproachTo}
                alt=""
                className="sm:absolute sm:top-[-164px]  sm:left-[56%] relative sm:h-[600px] sm:w-[534px] mb-[-22px]"
              />
            </div>
          </div>
        </div>
        <FormModal
          onOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          status={statusModal}
        />
      </section> */}
    </>
  );
};

export default KnowMore;
