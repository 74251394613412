import React from "react";
import styles from "../style";
import boostMood from "../assets/assets/boostMood.svg";
import drawing from "../assets/assets/drawing.svg";
import heartDetail from "../assets/assets/heartDetail.svg";
import moon from "../assets/assets/moon.svg";
import brain from "../assets/assets/brain.svg";
import happyMusic from "../assets/assets/happyMusic.svg";
import checkmark from "../assets/assets/checkmark.svg";
import DetailIndividualComponent from "./DetailIndividualComponent";


const DetailSection = () => {
  return (
    <>
      <div className={`mt-8 relative`}>
        {/* above content */}
        <div
          className={`xl:text-left xs:text-center lg:text-left sm:text-left md:text-left sm:px-[120px] sm:h-[332px] px-6 bg-[#F5F7FC] ${styles.flexCenter} flex-col sm:flex-row w-full justify-between sm:gap-[40px] lg:gap-[24%] md:gap-[321px] space-y-6 sm:space-y-0 sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem]`}
        >
          <div className="w-50%  text-[#742B88] font-bold md:text-[22px] sm:text-[20px] xs:text-[24px] lg:leading-[44px] xl:leading-[44px] md:leading-[44px] sm:leading-[44px] xs:leading-[36px] items-center lg:mt-[0px] xl:mt-[0px] md:mt-[0px] xs:mt-[80px]">
            Experience AI-powered custom playlists, that improve mental
            wellbeing
          </div>
          <div class="self-center md:hidden sm:hidden lg:hidden xs:block ">
            <div className={`relative self-center md:top-[-290px] lg:top-[-290px] sm:top-[0px] xs:top-[0px]`}>
              <img src={happyMusic} alt="happyMusic" className="w-full h-auto mx-auto lg:w-[608px] lg:h-auto"/>
            </div>
          </div>
          <div className="text-[#2B499E] font-bold md:text-[22px] sm:text-[20px] xs:text-[24px] lg:leading-[44px] xl:leading-[44px] md:leading-[44px] sm:leading-[44px] xs:leading-[36px]  items-center lg:mb-[0px] xl:mb-[0px] md:mb-[0px] xs:mb-[80px]">
            Find joy, relaxation, and anything else you need to feel, in
            minutes!
          </div>
        </div>

        <div class=" xl:text-left xs:text-center lg:text-left sm:text-left md:text-left grid sm:grid-cols-1 xs:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4 pb-2 xs:pt-16 sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem]" >
          <div className={`md:col-span-1 lg:col-span-1`}>
            <div className="flex  xl:flex-row xs:flex-col lg:flex-row sm:flex-row md:flex-row items-start">
              <div className="checkmark w-[36px] h-[36px] mr-[17px] xl:my-[0px] xs:my-[8px] lg:my-[0px] sm:my-[0px] md:my-[0px] xl:self-start lg:self-start sm:self-start md:self-start xs:self-center ">
                <img src={checkmark} alt={checkmark} />
              </div>
              <div className="flex flex-col gap-[8px] w-full xl:text-left xs:text-center lg:text-left sm:text-left md:text-left">
                <div
                  className={`non-italic font-medium sm:text-[20] text-[22px] leading-[36px] text-[#742B88]`}
                >
                  Promotes Emotional Regulation
                </div>
                <p className="w-full font-[450] sm:text[14px] text-[16px] leading-[28px] text-[#716480]">
                  Music has been shown to help people regulate their emotions,
                  reduce stress, and promote relaxation, which can have a
                  positive impact on overall well-being.
                </p>
              </div>
            </div>
          </div>
          <div class="col-span-2 self-center md:block sm:block lg:block xs:hidden ">
            <div className={`relative self-center md:top-[-290px] lg:top-[-290px] sm:top-[0px] xs:top-[0px]`}>
              <img src={happyMusic} alt="happyMusic" className="w-full h-auto mx-auto lg:w-[608px] lg:h-auto"/>
            </div>
          </div>
          <div class="md:col-span-1 lg:col-span-1 xl:text-left xs:text-center lg:text-left sm:text-left md:text-left">
            <div className=" flex xl:flex-row xs:flex-col lg:flex-row sm:flex-row md:flex-row items-start p-0">
              <div className="checkmark w-[36px] h-[36px] mr-[17px] xl:self-start lg:self-start sm:self-start md:self-start xs:self-center  xl:my-[0px] xs:my-[8px] lg:my-[0px] sm:my-[0px] md:my-[0px]">
                <img src={boostMood} alt={boostMood} />
              </div>
              <div className="xl:text-left xs:text-center lg:text-left sm:text-left md:text-left content flex flex-col  p-0 gap-[8px] w-full ">
                <div
                  className={`non-italic font-medium sm:text-[20] text-[22px] leading-[36px] text-[#742B88]`}
                >
                  Boosts Mood
                </div>
                <p className="w-full  font-[450] sm:text[14px] text-[16px] leading-[28px] text-[#716480]">
                  Music has the ability to elevate our mood, making us feel
                  happier and more energized. This can be especially helpful
                  during times of stress or low mood.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="xl:text-left xs:text-center lg:text-left sm:text-left md:text-left grid md:grid-cols-4 sm:grid-cols-1 xs:grid-cols-1 px-6 gap-4 mt-[-148px]  sm:mt-[0px] md:mt-[-250px] xs:mt-[0px] sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem]">
          <div class=" md:col-span-1 lg:col-span-1">
            <DetailIndividualComponent
              title="Improve Motivation & Creativity"
              icon={drawing}
              detail="Music has been shown to increase peoples creativity, inspiration and motivation by activating the brain's reward center, helping to stimulate the brain and create a positive mood conducive to new ideas and focused work."
              titleColor="#2B499E"
            />
          </div>

          <div class=" md:col-span-1 lg:col-span-1">
            <DetailIndividualComponent
              title="Fosters Community"
              icon={heartDetail}
              detail="Music has the power to bring people together and has been used for decades to foster social connection, which can have a positive impact on mental health and overall well-being."
              titleColor="#742B88"
            />
          </div>

          <div class=" md:col-span-1 lg:col-span-1">
            <DetailIndividualComponent
              title="Aids in Relaxation"
              icon={moon}
              detail="Listening to soothing music before bed or when overwhelmed can help promote relaxation and improve sleep quality, which is essential for physical and mental health."
              titleColor="#2B499E"
            />
          </div>

          <div class=" md:col-span-1 lg:col-span-1">
            <DetailIndividualComponent
              title="Enhances Focus and Productivity"
              icon={brain}
              detail="Certain types of music, such as instrumental or classical, have been shown to enhance focus and productivity. This can be useful for studying, working, or other tasks that require concentration."
              titleColor="#742B88"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailSection;
