import { useState } from "react";
import {useNavigate } from "react-router-dom";
import styles from "../style";
import Button from "./Button";
import imageVector from "../assets/assets/image-vector.svg";
import FormModal from "./FormModal";
const Hero = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const statusModal = (flag) => {
    setOpen(flag);
    navigate("/");
  };
  return (
    <section
      id="home"
      className={`grid grid-cols-12  ${styles.flexCenter} ${styles.paddingY}`}
    >
       <FormModal
          onOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          status={statusModal}
        />
      <div
        className={`lg:col-span-7 md:col-span-7 sm:col-span-7 xs:col-span-12 sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem]`}
      >
        {/* hero heading text */}
        <div className="text-[#ffffff] font-black not-italic 
        xl:text-[60px] xl:py-[6px] xl:text-left xl:leading-[60px] xl:px-[0px]
        lg:text-[60px] lg:py-[6px] lg:text-left lg:leading-[60px] lg:px-[0px]
        md:text-[40px] md:py-[6px] md:text-left md:leading-[40px] md:px-[0px]
        sm:text-[40px] sm:py-[6px] sm:text-left sm:leading-[40px] sm:px-[0px] sm:w-max
        xs:text-[44px] xs:text-center xs:leading-[48px] xs:px-[24px]">
          Can't shake the blues?
        </div>

        {/* sub heading text for hero */}
        <div className="text-[#EDE9F8] sm:w-max w-full non-italic font-bold text-[26px] md:text-[24px] sm:text-[18px] xs:text-[22px] xs:font-[450] xs:text-center xs:leading-9 flex flex-row justify-between items-center">
          Reach your goal vibes with AI-powered music that moves you.
        </div>

        {/* button */}
        <div className="flex justify-items-center xs:flex-col">
  <a href="https://app.feelyoumusic.com/" target="_blank" className="flex lg:justify-start xl:justify-start md:justify-start sm:justify-start  xs:justify-center">
    <Button
      styles={`px-[32px] py-[16px] sm:mt-[80px] mt-[20px] lg:block md:block sm:block xs:hidden`}
      text="Test FeelYou™ Music BETA Version"
    />
    <Button
      styles={`px-[32px] py-[16px] sm:mt-[80px] mt-[20px] lg:hidden md:hidden sm:hidden xs:block`}
      text="Test FeelYou™ Music (BETA)"
    />
  </a>
</div>
       
            
      </div>

      {/* vector image */}
      <div
        className={`lg:col-span-4 md:col-span-4  sm:col-span-4  xs:col-span-12 md:my-0 my-10 relative`}
      >
        <img
          src={imageVector}
          alt="fym-child"
          className="w-[100%] h-[100%] relative z-[5] md:ml-[80px] sm:ml-[60px]"
        />
      </div>
    </section>
  );
};

export default Hero;
