import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../style";
import Button from "./Button";

const About = () => {
  const navigate = useNavigate();
  return (
    <section className="flex flex-col min-h-screen text-white">
      <section className={`px-6 mx-auto  w-full items-center sm:px-16 `}>
        <div className="py-[80px] md:px-48 sm:px-4 gap-[40px] text-center flex flex-col justify-center items-center containerOfAbout mt-6">
          <div className="headTextOfAboutSection xs:text-[24px] lg:text-[48px] sm:text-[48px] md:text-[48px] xs:leading-[24px] lg:leading-[48px] sm:leading-[48px] md:leading-[48px] ">
            Feelyou™ Music is on a mission to help people connect, heal and
            thrive.
          </div>
          <div className="subHeadTextForAboutSection xs:text-[22px] lg:text-[28px] sm:text-[28px] md:text-[28px] xs:leading-[22px] lg:leading-[46px] sm:leading-[46px] md:leading-[46px] lg:font-[700] xl:font-[700] md:font-[700] sm:font-[700] xs:font-[450]">
            Bringing the healing power of music to your fingertips.{" "}
          </div>
          <div className="contentOfAbout">
            Founded by Sarah Shelden, who personally struggled with mental
            health and anxiety, but found solace in music. At Feelyou™ our
            mission is to create a tool that emphasizes the benefits of music
            for processing emotions, healing, and helping people to know they
            are not alone. After years of research and development, Feelyou™
            Music is closer than ever to becoming the resource that people need
            during the current mental health crisis, and we are proud to share
            our BETA version with testers.
          </div>
          <div className="buttons flex flex-col sm:flex-row gap-6">
            <a href="https://app.feelyoumusic.com/" target="_blank">
              <Button
                styles={`aboutbt-1 text-[#742B88] font-medium mx-auto leading-6`}
                text="Try the FeelYou™ Music BETA"
              />
            </a>
            <button className="aboutbt-2 text-[#F5F7FC] font-medium mx-auto" onClick={()=>{
navigate('/founding-feelyou')
            }}>
              Founding FeelYou™
            </button>
          </div>
        </div>
      </section>
    </section>
  );
};

export default About;
