import React from "react";
import { Navbar } from "../components";
import AboutContent from "../components/About";
import styles from "../style";
const About = () => {
  return (
    <div className="grid grid-cols-4">
      <div className={`col-span-4 ${styles.flexCenter}`}>
        <div
          className={`sm:min-h-screen bg-white bg-about-img bg-center bg-cover bg-blend-multiply`}
        >
          <Navbar />
          <AboutContent />
        </div>
      </div>
    </div>
  );
};

export default About;
