import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import close from "../assets/close.svg";
import menu from "../assets/menu.svg";
import logo from "../assets/assets/logo.svg";
import { navLinks } from "../constants";
import Button from "./Button";
import FormModal from "./FormModal";

const Navbar = ({ bg }) => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [open, setOpen] = useState(false);
  // const appropriateHeaderColor =
  //   location.pathname === "/"
  //     ? "bg-[#2F479C]"
  //     : location.pathname === "/about"
  //     ? "bg-[#13172E]"
  //     : location.pathname === "/founding-feel-you"
  //     ? "bg-navbar-pattern"
  //     : location.pathname === "/learn-more"
  //     ? "bg-[#09135D]"
  //     : "transparent";

  // const appropriateBackground =
  //   location.pathname === "/"
  //     ? "bg-hero-pattern"
  //     : location.pathname === "/about"
  //     ? "bg-about-img"
  //     : location.pathname === "/founding-feel-you"
  //     ? "bg-founding-gradient"
  //     : location.pathname === "/learn-more"
  //     ? "learn-more-gradient"
  //     : "trasnsparent";

  // const showingAppropriateContent =  location.pathname === "/"
  // ? <Hero />
  // : location.pathname === "/about"
  // ? <About />
  // : location.pathname === "/founding-feel-you"
  // ? <Founding />
  // : location.pathname === "/learn-more"
  // ? <LearnMore />
  // : "trasnsparent";

  const statusModal = (flag) => {
    setOpen(flag);
    navigate("/");
  };
  return (
    <div className={``}>
      {/* using bg svg accordinng to router navigation */}

      <div className={``}>
        <nav
          className={`w-full flex py-6 sm:py-[32px] sm:px-[20px] md:px-[60px] lg:px-[60px] xs:pl-[1.5rem] justify-between items-center navbar ${
            location.pathname === "/founding-feelyou" ? "navbg" : "transparent"
          }`}
        >
          {/* logo */}
          <Link to={"/"}>
            <img
              src={logo}
              alt="fym"
              className="md:w-[207.91px] sm:w-[170px] lg:w-[207.91px] xs:w-[207.91px] h-[40px] md:pr-4 sm:pr-4 lg:pr-4"
            />
          </Link>

          {/* links of menu */}
          <ul className="list-none sm:flex hidden justify-end items-center">
            {navLinks.map((nav, index) => (
              <a
                href={nav.id}
                key={nav.id}
                className={`font-normal cursor-pointer md:text-[18px] sm:text-[16px] ${
                  location.pathname.replace("/", "") ===
                  nav.title.toLowerCase().replace(" ", "-")
                    ? "text-[#F0F0FF] underline decoration-4  underline-offset-8"
                    : "text-[#F0F0FF]"
                } ${
                   "md:pr-[24px] sm:px-[8px] lg:pr-[34px]"
                }`}
                onClick={() => setActive(nav.title)}
              >
                {nav.title}
              </a>
            ))}
          </ul>

          {/* button for web version */}
          <Button
            styles={`md:px-[32px] sm:px-[22px] py-[16px] hidden sm:block text-[18px] font-medium`}
            text="Become a BETA Tester"
            onClick={() => {
              setOpen(true);
            }}
          />

          {/* This is for the mobile view */}
          <div className="sm:hidden flex flex-1 justify-end items-center px-[1.5rem]">
            {/* logo */}
            <img
              src={toggle ? close : menu}
              alt="menu"
              className="w-[28px] h-[28px] object-contain"
              onClick={() => setToggle(!toggle)}
            />

            {/* menu */}
            <div
              className={`${
                !toggle ? "hidden" : "flex"
              } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar z-50`}
            >
              <ul className="list-none flex justify-end items-start flex-1 flex-col">
                {navLinks.map((nav, index) => (
                  <a
                    href={nav.id}
                    key={nav.id}
                    className={`font-poppins font-medium cursor-pointer text-[16px] ${
                      active === nav.title
                        ? "text-[#F0F0FF] underline"
                        : "text-[#F0F0FF]"
                    } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                    onClick={() => setActive(nav.title)}
                  >
                    {nav.title}
                  </a>
                ))}
              </ul>
            </div>
          </div>
        </nav>
        <FormModal
          onOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          status={statusModal}
        />
      </div>
    </div>
  );
};

export default Navbar;
