import React from "react";

const Button = ({ styles, text, onClick }) => (

    <button
      type="button"
      className={`bg-[#F5F7FC] hoverBtnSc text-[#2B499E] font-medium text-[18px] leading-4 rounded-[30px] outline-none ${styles}`}
      onClick={onClick}
    >
      {text}
    </button>
 
);

export default Button;
