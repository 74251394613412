import React from "react";
import LearnMoreContent from "../components/LearnMoreContent";
import { Navbar } from "../components";
import styles from "../style";

const LearnMore = () => {
  return (
    <div className="w-full grid grid-cols-4">
      <div className={`col-span-4 ${styles.flexCenter}`}>
        <div
          className={`bg-white bg-learnMore-img bg-center bg-cover bg-blend-multiply`}
        >
          <Navbar />
          <LearnMoreContent />
        </div>
      </div>
    </div>
  );
};
export default LearnMore;
