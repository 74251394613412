import React from "react";
import IndividualTimeline from "./IndividualTimeline";
import icon1 from "../assets/assets/frames/1.svg";
import icon2 from "../assets/assets/frames/2.svg";
import icon3 from "../assets/assets/frames/3.svg";
import icon4 from "../assets/assets/frames/4.svg";
import icon5 from "../assets/assets/frames/5.svg";
import icon6 from "../assets/assets/frames/6.svg";
import icon7 from "../assets/assets/frames/7.svg";
import icon8 from "../assets/assets/frames/8.svg";
import { gridForTimeline } from "../constants";

const Timeline = () => {
  return (
    <div>
      <div className="feelyoutextContainer mt-[60px]">
        <div className="dash" />
        <div className="feelyoutext">Founding FeelYou™</div>
      </div>

      <div className="mt-[40px] flex flex-col justify-items-center" >
        <div className="our-journey xl:text-left xs:text-center lg:text-left sm:text-left md:text-left lg:text-[90px] xl:text-[90px] md:text-[90px] sm:text-[44px] xs:text-[44px]">Our Journey</div>
 
        {/* grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-1 sm:mt-[70px] text-center lg:bg-wave-img  md:bg-wave-img md:bg-auto md:bg-no-repeat md:bg-custom">
             <IndividualTimeline icon={icon1} year="2011" detail="Our founder, Sarah Shelden struggled with mental health and found that the therapeutic power of music inspired her to create an app that could help others like her to heal." />
            <IndividualTimeline icon={icon2} year="2014" detail="Sarah took a medical leave from earning her neuroscience degree, after being misdiagnosed, causing her to develop severe side effects to the new medications." />
            <IndividualTimeline icon={icon3} year="2017" detail="Sarah began learning how to code and enrolled in Georgia Tech while continuing to develop her idea and prototype for the FeelYou™ Music app, still relying on the comfort of music in her life." />
            <IndividualTimeline icon={icon4} year="2018" detail="Sarah conducted market research on her prototype, receiving feedback from hundreds of people that helped validate her idea. However, she encountered significant stigma around mental health and knew there was still a long way to go." />
            <IndividualTimeline icon={icon5} year="2019" detail="Sarah networked tirelessly to help grow her startup, eventually connecting with Sanicon Services and other amazing designers, developers and marketers. The team began collaborating and validating the concept for FeelYou™ Music." />
            <IndividualTimeline icon={icon6} year="2020" detail="The FeelYou™ Music team continued to develop the app and its algorithm to create the best experience for our users. Despite the COVID-19 Pandemic, web traffic and sign-ups increased, and Sarah became more active in her work to diminish the stigma around mental health." />
            <IndividualTimeline icon={icon7} year="2021" detail="FeelYou™ Music continued to develop the app and its algorithm while the team continued to grow, in the support of Sarah’s drive and vision. Through repetitive design, development, feedback and iteration, FeelYou™ Music was starting to take shape." />
            <IndividualTimeline icon={icon8} year="2022" detail="After being founded nearly a decade prior, FeelYou™ Music had finally developed their BETA version. Sharing it with users and gaining product feedback to make our product even better!" /> 
            {/* {gridForTimeline.map((e) => (
            <img key={e.id} src={e.img} alt={e.id} />
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
