import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Footer, ScrollToTop } from "./components";
import About from "./pages/About";
import Founding from "./pages/Founding";
import Home from "./pages/Home";
import LearnMore from "./pages/LearnMore";
import styles from "./style";

const App = () => (
  <Router>
    <div >
      {/* <Navbar /> */}
      <ScrollToTop />
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="about-us" element={<About />} />
        <Route path="founding-feelyou" element={<Founding />} />
        <Route path="learn-more" element={<LearnMore />} />
      </Routes>

      <div className="grid grid-cols-4 gap-4">
        <div className={`col-span-4`}>
          <Footer />
        </div>
      </div>

    </div>
  </Router>
);

export default App;
