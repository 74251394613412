import React from "react";
import styles from "../style";
import listeningMusic from "../assets/assets/listeningMusic.svg";

const FoundingMain = () => {
  return (
    <section id="home" className={`grid grid-cols-12 ${styles.flexCenter} ${styles.paddingY} lg:gap-x-28 md:gap-x-28 sm:gap-x-8 sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem]`}>
      <div className="col-span-12">
      <div className="feelyoutextContainer">
            <div className="dash" />
            <div className="feelyoutext">Founding FeelYou™</div>
          </div>
      </div>

      <div className="md:col-span-5 lg:col-span-5 sm:col-span-4 xs:col-span-12 md:block sm:block lg:block xs:hidden ">
      <img
            src={listeningMusic}
            alt="listeningMusic"
            className="w-[100%] h-[100%] relative"
          />
      </div>
      <div className=" md:col-span-7 lg:col-span-7 sm:col-span-8 xs:col-span-12 xl:text-left xs:text-center lg:text-left sm:text-left md:text-left">
      <div className="foundingHeadText font-bold md:text-[56px] lg:text-[56px] sm:text-[40px] xs:text-[32px] md:leading-[66px] lg:leading-[66px] sm:leading-[44px] xs:leading-[36px] sm:mt-0 mt-8">
            Because music heals, when words can't
          </div>
          <br />
          <div className="subHeadTextForFounding">
            Founded by CEO Sarah Shelden, who personally struggled with anxiety
            and found solace in music, our mission is to create an app that
            emphasizes the benefits of music therapy and helps people know they
            are not alone. Our team knows the struggle, thats why we are
            committed to providing accessible and intuitive musical healing to
            as many people as possible.{" "}
          </div>
          <br /><br />
          <div className="thirdSmallHeadTextForFounding">
            After years of research and development, FeelYou™ Music is closer
            than ever to becoming the resource that people need during the
            current mental health crisis.
          </div>
      </div>
      <div className="md:col-span-5 lg:col-span-5 sm:col-span-4 xs:col-span-12 md:hidden sm:hidden lg:hidden xs:block ">
      <img
            src={listeningMusic}
            alt="listeningMusic"
            className="w-[100%] h-[100%] relative"
          />
      </div>
    </section>
  );
};

export default FoundingMain;
