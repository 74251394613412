import React, { useState } from "react";
// import instaGram from "../assets/"
import {  navLinks2 } from "../constants";
import social from "../assets/assets/Social.svg";
import { Link ,useLocation} from "react-router-dom";
// import Instagram from "../assets/assets/icons8-instagram.svg";
import LinkedIn from "../assets/LinkedIn.png";
import Instagram from "../assets/Insta.png";
import Twitter from "../assets/Twitter.png";
import Fb from "../assets/Fb.png";
import TickTok from "../assets/tiktok.png";
import Ytube from "../assets/Ytube.png";

const Footer = () => {
  const [active, setActive] = useState("");
  const location = useLocation();
  return (
    <footer className="footer sm:mt-0">
      <div className=" flex flex-row items-start h-[40px] mx-auto p-0">
        <div className="w-[40px] h-[40px] social-icon">
          <a href="https://www.instagram.com/feelyou_music/" target="_blank"  rel="noreferrer">
            <img src={Instagram} alt="Instagram" />
          </a>
        </div>
        {/* <div className="w-[40px] h-[40px] social-icon">
          <a href="#">
            <img src={Twitter} alt="Twitter" />
          </a>
        </div> */}
        <div className="w-[40px] h-[40px] social-icon">
          <a href="https://www.linkedin.com/company/feelyoumusicinc?original_referer=https%3A%2F%2Fwww.google.com%2F" target="_blank"  rel="noreferrer">
            <img src={LinkedIn} alt="LinkedIn" />
          </a>
        </div>
        {/* <div className="w-[40px] h-[40px] social-icon">
          <a href="https://www.tiktok.com/@feelyoumusic?_t=8bPYW32GAaA&_r=1" target="_blank"  rel="noreferrer" >
            <img src={Fb} alt="Fb" />
          </a>
        </div> */}
        <div className="w-[40px] h-[40px] social-icon rounded-full">
          <a href="https://www.tiktok.com/@feelyoumusic?_t=8bPYW32GAaA&_r=1" target="_blank"  rel="noreferrer">
            <img src={TickTok} alt="TickTok" />
          </a>
        </div>
        <div className="w-[40px] h-[40px] social-icon">
          <a href="https://www.youtube.com/@feelyoumusic4933" target="_blank"  rel="noreferrer">
            <img src={Ytube} alt="Ytube" />
          </a>
        </div>
      </div>

      <ul className="list-none flex justify-end items-start flex-1 flex-row  xl:gap-x-8 xs:gap-x-[12px] lg:gap-x-8 sm:gap-x-8 md:gap-x-8 xl:text-[16px] xs:text-[11px] lg:text-[16px] sm:text-[16px] md:text-[16px] text-[18px]">
    {navLinks2.map((nav, index) => (
      <Link
        to={nav.id}
        key={nav.id}
        className={`font-medium cursor-pointer  leading-[24px] xl:text-[16px] xs:text-[12px] lg:text-[16px] sm:text-[16px] md:text-[16px]  ${
          location.pathname.replace('/', '') === nav.title.toLowerCase().replace(' ',"-") || (location.pathname=== '/' && nav.title === "Home") ? "text-[#F0F0FF]  underline decoration-4  underline-offset-8" : "text-[#F0F0FF]"
        } ${index === navLinks2.length - 1 ? "mb-0" : "mb-4"}`}
        onClick={() => setActive(nav.title)}
      >
        {nav.title}
      </Link>
    ))}
      </ul>

      {/* <div className="footer-end">FeelYou Music™ 2023 | All Rights Reserved</div> */}
      <div className="font-medium text-[16px] leading-[28px] text-center text-[#B5AAC0]">FeelYou™ Music 2023 | All Rights Reserved</div>
      <div className="font-medium text-[16px] leading-[28px] text-center text-[#B5AAC0]">
         <Link to={'https://app.termly.io/document/terms-and-conditions/66081019-0301-4adc-a019-96174dee9554 '} target="_blank">Terms of Use</Link> | {' '} 
         <Link to={'https://app.termly.io/document/privacy-policy/fc11c98e-7b11-4bba-b3a3-b6ce6f3ff86b'} target="_blank">Privacy Policy
</Link> | {' '} 
         <Link to={'https://app.termly.io/document/cookie-policy/e9488fd3-de59-4d47-8d36-fede3b35970c'} target="_blank">Cookie Policy
</Link> |   {' '} 
         <Link to={'https://app.termly.io/document/disclaimer/f6b90bc0-110d-4d0b-bd40-c79b74af8d9c'} target="_blank">Disclaimer
</Link> |   {' '} 
         <Link to={'https://app.termly.io/document/acceptable-use-policy/ff8f9677-3f3d-461f-b35d-df30eec13dab'} target="_blank">Acceptable Use Policy
</Link> 
      
      </div>
      {/* <SignupForm /> */}
    </footer>

  );
};

export default Footer;
