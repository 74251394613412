import React from "react";

const DetailIndividualComponent = ({title, icon, detail, titleColor}) => {
  return (
    <div className="flex xl:flex-row xs:flex-col lg:flex-row sm:flex-row md:flex-row items-start p-0">
      <div className="w-[36px] h-[36px] mr-[17px] xl:self-start lg:self-start sm:self-start md:self-start xs:self-center xl:my-[0px] xs:my-[8px] lg:my-[0px] sm:my-[0px] md:my-[0px]">
        <img src={icon} alt={title} />
      </div>
      <div className="flex flex-col xl:text-left xs:text-center lg:text-left sm:text-left md:text-left p-0 w-full">
        <div className={`non-italic font-medium sm:text-[20] text-[22px] leading-[36px] text-[${titleColor}] `}>
          {title}
        </div>
        <p className="font-[450] sm:max-w-max sm:text[14px] text-[16px] leading-[28px] text-[#716480]">
         {detail}
        </p>
      </div>
    </div>

  );
};

export default DetailIndividualComponent;
