import React from "react";
import { Navbar } from "../components";
import Ankush from "../components/Ankush";
import FoundingKnowMore from "../components/FoundingKnowMore";
import FoundingMain from "../components/FoundingMain";
import SarahShelden from "../components/SarahShelden";
import Timeline from "../components/Timeline";
import styles from "../style";

const Founding = () => {
  return (
    <div className="w-full  grid grid-cols-4">
      <div className={`col-span-4 `}>
        <div
          className={`bg-white bg-founding-gradient bg-center bg-cover lg:pb-12 md:pb-12 sm:pb-12 xs:pb-24 lg:bg-founding-gradient md:bg-founding-gradient sm:bg-founding-gradient xs:bg-wave-img-g-mb`}
        >
          <Navbar bg="bg-navbar-pattern" />
          <FoundingMain />
        </div>
      </div>
      <div className={`bg-white col-span-4 ${styles.flexCenter} sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem]`}>
        <div className={``}>
          <Timeline />
        </div>
      </div>

      <div className={`bg-white col-span-4 ${styles.flexCenter}  sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem]`}>
        <div className={``}>
          <SarahShelden />
        </div>
      </div>

      <div className={`col-span-4 sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem] ${styles.paddingY}`}>
        <div className={``}>
          <FoundingKnowMore />
        </div>
      </div>
      {/* <Ankush /> */}

      <div className={`col-span-4 `}>
        <div className={``}>
          <Ankush />
        </div>
      </div>
    </div>
  );
};

export default Founding;
