import { img1, img2, img3, img4, img5, img6, img7, img8 } from "../assets/assets/timelineGrids";

export const navLinks = [
  {
    id: "about-us",
    title: "About Us",
  },
  {
    id: "founding-feelyou",
    title: "Founding Feelyou",
  },
  {
    id: "learn-more",
    title: "Learn More",
  },
];

export const navLinks2 = [
  {
    id: "/",
    title: "Home"
  },
  {
    id: "about-us",
    title: "About Us",
  },
  {
    id: "founding-feelyou",
    title: "Founding Feelyou",
  },
  {
    id: "learn-more",
    title: "Learn More",
  },
];

export const gridForTimeline = [
  {
    id: 1,
    img: img1
  },
  {
    id: 2, 
    img: img2
  },
  {
    id: 3, 
    img: img3
  },
  {
    id: 4, 
    img: img4
  },
  {
    id: 5, 
    img: img5
  },
  {
    id: 6, 
    img: img6
  },
  {
    id: 7, 
    img: img7
  },
  {
    id: 8, 
    img: img8
  },
]
