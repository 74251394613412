import React from "react";
import styles from "../style";
import Button from "./Button";
import Circle from "./Circle";
import heart from "../assets/assets/heart.svg";
import computer from "../assets/assets/computer.svg";
import headphone from "../assets/assets/headphone.svg";

const CircleSection = () => {
  return (
    <section
      className={`${styles.flexCenter} flex-col xl:px-0 sm:px-[120px] gap-[64px] sm:mt-[40px] sm:py-[80px] sm:px-[20px] md:px-[60px] lg:px-[60px] xs:px-[1.5rem]`}
    >
      <div className="gap-[24px]">
        <p className="fym-text-gradient font-bold text-center md:text-[56px] lg:text-[56px] sm:text-[40px] xs:text-[24px] md:leading-[66px] lg:leading-[66px] sm:leading-[44px] xs:leading-[36px] md:px-32">
          FeelYou™ Music is an AI-driven Music App for Stress Management
        </p>
        <p className="sm:mt-[24px] text-center md:font-bold lg:font-bold sm:font-bold xs:font-[450] md:text-[24px] lg:text-[24px] sm:text-[18px] xs:text-[22px] leading-[34px] text-[#8D7D9C]">
          We help you set the mood for however you want to feel!
        </p>
      </div>

      {/* circles */}
      <div
        className={`grid md:grid-cols-1  lg:grid-cols-3  sm:grid-cols-1 xs:grid-cols-1  gap-8 m-auto`}
      >
        <Circle
          title="Set your Vibe"
          icon={heart}
          detail="FeelYou™ Music asks you how you’re feeling right now, and how you want to feel."
          textColor="#742B88"
        />
        <Circle
          title="Get your Playlist"
          icon={computer}
          detail="Our AI creates a custom playlist of music according to your needs."
          textColor="#2B499E"
        />
        <Circle
          title="Improve your Experience"
          icon={headphone}
          detail="Rate your experience, so we can help you reach your goal even faster next time!"
          textColor="#742B88"
        />
      </div>

      <p className="font-medium text-[16px] leading-[28px] text-center text-[#8D7D9C]">
        Our features are limited prior to full release, we are currently in BETA
        development.
      </p>
      <a href="https://app.feelyoumusic.com/" target="_blank"  rel="noreferrer" >
        <Button
          styles={`hoverBtn px-[32px] py-[16px] bg-gradient-to-r from-[#2B499E] to-[#742B88]  text-[#fff]`}
          text="Try FeelYou™ Music BETA"
          link={`https://app.feelyoumusic.com/`}
        />
      </a>
    </section>
  );
};

export default CircleSection;
