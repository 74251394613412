import React, { useState } from "react";
import MyModal from "./MyModal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';


const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  your_music: yup.string().required("Your Fave Music is required"),
});

const FormModal = ({ onOpen, onClose, status }) => {
  const [thanksMsgShow, setThanksMsgShow] = useState(false);
  const [errorMsgShow, setErrorMsgShow] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit =  async (data) => {
      const token = 'fym-token-eyJjdXJyZW50RGlyIjoiL2FwaS5mZWVseW91bXVzaWM';
      try {
          const formdataOtp = new FormData();
          formdataOtp.append("name", data.name);
          formdataOtp.append("email", data.email);
          formdataOtp.append("token", token);
          formdataOtp.append("your_music", data.your_music);
          console.log(formdataOtp)

          const config = {
              headers: {'content-type': 'multipart/form-data'}
          }

          await axios.post(`https://api.feelyoumusic.com/early_signup_api.php`, formdataOtp, config)
              .then(response => {
                  console.log("response", response);
                  if (response.data.error === false) {
                    setThanksMsgShow(true);
                  }else{
                    setErrorMsgShow(true);
                  }
              })
              .catch(error => {
                  console.log(error);
              });


      } catch (error) {
          console.log('error>>>>', error)
          // return error
      }

      
  };
  const redirectHome = () => {
    setThanksMsgShow(false);
    setErrorMsgShow(false);
    status(false);
  };

  const errorContent = (
    <>
      <div className="flex flex-col justify-center pt-[28px]">
        <div className="text-center text-5xl">😥</div>
        <div className="fym-text-gradient font-bold text-center py-4  sm:text-3xl lg:text-3xl md:text-3xl xs:text-lg ">
          Something went wrong
        </div>
        <div className="text-[#8D7D9C] text-center leading-7 text-xs sm:px-16 xs:px-4 md:px-16 lg-px-16">
          We’re sorry! We were unable to sign you up for FeelYou™ Music BETA
          testing. Please try again, or come back later! Try Again
        </div>
        <div className="flex justify-center pt-[28px]">
          <button
            type="button"
            className=" bg-gradient-to-r from-[#2B499E] to-[#742B88] text-[#fff] py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
            onClick={() => {
              setThanksMsgShow(false);
              setErrorMsgShow(false);
            }}
          >
            Try Again
          </button>
        </div>
      </div>
    </>
  );

  const thanksContent = (
    <>
      <div className="flex flex-col justify-center pt-[28px]">
        <div className="text-center text-5xl">🎉</div>
        <div className="fym-text-gradient font-bold text-center py-4  sm:text-3xl lg:text-3xl md:text-3xl xs:text-lg ">
          Thank you!
        </div>
        <div className="text-[#8D7D9C] text-center leading-7 text-xs sm:px-16 xs:px-4 md:px-16 lg-px-16">
          You have successfully signed up to test the FeelYou™ Music BETA! You’ll
          receive information on how to get started, and get feeling, though
          your email.
        </div>
        <div className="flex justify-center pt-[28px]">
          <button
            type="button"
            className=" bg-gradient-to-r from-[#2B499E] to-[#742B88] text-[#fff] py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
            onClick={redirectHome}
          >
            Home
          </button>
        </div>
      </div>
    </>
  );
  const content = (
    <>
      <div className="fym-text-gradient font-bold text-center py-4 sm:text-3xl lg:text-3xl md:text-3xl xs:text-lg ">
        FeelYou™ Music needs BETA testers!
      </div>
      <div className="text-[#8D7D9C] text-center leading-5 text-xs px-4">
        Sign up to become a BETA tester to get exclusive access to our
        AI-powered music app, and help us shape the future of FYM with your
        personal feedback! So join us, find your perfect vibe, and help us make
        FYM even better!
      </div>
      <div className="w-full max-w-md mx-auto mt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block fym-text-gradient font-bold mb-2"
            >
              Your Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={`${
                errors.name ? "border-red-500" : ""
              } shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
              {...register("name")}
            />
            {errors.name && (
              <p className="text-red-500 text-xs italic">
                {errors.name.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block fym-text-gradient font-bold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={`${
                errors.email ? "border-red-500" : ""
              } shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
              {...register("email")}
            />
            {errors.email && (
              <p className="text-red-500 text-xs italic">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="your_music"
              className="block  font-bold mb-2"
            >
             
              <span className="fym-text-gradient">
              Your Fave Music{" "}
              </span>
              <span className="text-[#8D7D9C]  leading-5 text-xs">
                (Song, soundtrack, whatever comes to mind!)
              </span>
            </label>
            <input
              id="your_music"
              name="your_music"
              className={`${
                errors.your_music ? "border-red-500" : ""
              } shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
              {...register("your_music")}
            />
            {errors.your_music && (
              <p className="text-red-500 text-xs italic">
                {errors.your_music.message}
              </p>
            )}
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className=" bg-gradient-to-r from-[#2B499E] to-[#742B88] text-[#fff] py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
  return (
    <MyModal
      isVisible={onOpen}
      onClose={onClose}
      content={
        errorMsgShow ? errorContent : thanksMsgShow ? thanksContent : content
      }
    />
  );
};
export default FormModal;
