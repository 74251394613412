import React from "react";
import styles from "../style";
import SarahSheldenImage from "../assets/Sarah_Sheldon 1.png";
import Sign from "../assets/assets/sign.svg";

import StartComma from "../assets/startComma.png";
import SarahSign from "../assets/sarahSign.png";
import EndComma from "../assets/endComma.png";

const SarahShelden = () => {
  return (
    <section
        className={`grid grid-cols-12 ${styles.flexCenter} ${styles.paddingY} lg:gap-x-20 md:gap-x-28 sm:gap-x-8`}
      >
        <div className="col-span-12">
        <div className="feelyoutextContainer">
              <div className="dash"></div>
              <div className="feelyoutext">Founding FeelYou™</div>
            </div>
        </div>
        <div className="our-journey col-span-12 xl:text-left xs:text-center lg:text-left sm:text-left md:text-left lg:text-[90px] xl:text-[90px] md:text-[90px] sm:text-[44px] xs:text-[44px] md:hidden sm:hidden lg:hidden xs:block mt-[12px]">Our Founder</div>
        <div className=" md:Our Founder lg:col-span-7 sm:col-span-8 xs:col-span-12 xl:text-left xs:text-center lg:text-left sm:text-left md:text-left">
        <div className="py-[10px] md:ml-[-50px] sm:ml-[-10px] xs:ml-[-20px]">
           <img src={StartComma} alt="" className="md:block sm:block lg:block xs:hidden" /></div>
            <div className="sm:pr-[40px] gap-[24px] flex flex-col sheldenTextPara mx-auto">
        
              <div>
                As I reflect on my journey with Feelyou™ Music, I am thrilled
                that we are getting closer to our mission of providing people
                like me, and like you, with a valuable resource to begin their
                unique healing journey. There is a mental health crisis in our
                world, that’s why modern and accessible health tools are more
                important than ever before. Despite the stigma, music has proven
                to be a powerful tool in helping people work through their
                feelings, and feel more empowered in their daily lives.
              </div>
              <div>
                That's why we created an app that uses the power of music to
                remind you - you are not alone, and that despite everything
                you’re going through, you will be okay.
              </div>
            
            </div>
            <div className="flex flex-col gap-[24px]">
            <div className="xl:mt-[0px] lg:mt-[0px] md:mt-[0px] sm:mt-[0px] xs:mt-[10px] xl:place-self-start xs:place-self-center lg:place-self-start sm:place-self-start md:place-self-start wefeelYouText py-[10px] xl:text-left xs:text-center lg:text-left sm:text-left md:text-left">We feel you.</div>
            <div className="place-self-end mt-[-85px] xs:ml-[28px] md:block sm:block lg:block xs:hidden"> <img src={EndComma} alt="" /></div>
            </div>
            <div className="mt-[40px] md:block sm:block lg:block xs:hidden">
                <img src={Sign} alt="" className="" />
           </div>
           <div className="my-[40px] flex justify-center md:hidden sm:hidden lg:hidden xs:block">
                <img src={SarahSign} alt="" className="mx-auto" />
        </div>
        </div>
        
        <div className="md:col-span-5 lg:col-span-5 sm:col-span-4 xs:col-span-12 sm:mt-[-208px] md:mt-[0px] lg:mt-[0px] ">
        <div
            className={`sarah-pic`}
          >
            <img src={SarahSheldenImage} alt="sarahShelden" className="mb-[-90px]" />
          </div>
        </div>
  
      </section>
  );
};

export default SarahShelden;
